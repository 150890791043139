'use strict';

/* global URL */

const Swiper = require('swiper/swiper-bundle.min.js');

/**
 * Validates and Return the cquotient namespace provided by the commerce cloud platform
 * @returns {Object} - einsteinUtils or null
 */
function getEinsteinUtils() {
    var einsteinUtils = window.CQuotient;
    if (einsteinUtils && (typeof einsteinUtils.getCQUserId === 'function') && (typeof einsteinUtils.getCQCookieId === 'function')) {
        return einsteinUtils;
    }
    return null;
}

/**
 * fills in the carousel with product tile html objects
 * @param {string} einsteinResponse string html for product tiles
 * @param {jQuery} $parentElement parent element where recommendations will show.
 */
function fillDomElement(einsteinResponse, $parentElement) {
    var recommendedProducts = einsteinResponse[$parentElement.data('recommender')].recs;
    // uncommenting the next 2 lines will add in test products for when einstein isn't returning results from the recommender
    // var testProds = [{ id: '028292' }, { id: '031239' }, { id: '030242' }, { id: '031242' }, { id: '029376' }, { id: '030293' }, { id: '229785' }];
    // recommendedProducts = recommendedProducts.concat(testProds);

    if (recommendedProducts && recommendedProducts.length > 0) {
        var template = $parentElement.data('template');
        var swatches = $parentElement.data('swatches');
        var displayRatings = $parentElement.data('displayratings');
        var components = [];
        components = recommendedProducts.map(function (recommendedProduct) {
            var tiledefinition = {};
            tiledefinition.template = template;
            tiledefinition.swatches = swatches;
            tiledefinition.displayratings = displayRatings;
            tiledefinition.model = {
                type: 'product',
                id: recommendedProduct.id
            };
            return tiledefinition;
        });

        var url = new URL($parentElement.data('product-load-url'));
        url.searchParams.append('components', JSON.stringify(components));
        url.searchParams.append('limit', $parentElement.data('limit'));
        $.ajax({
            url: url.href,
            type: 'get',
            dataType: 'html',
            success: function (html) {
                $parentElement.find('.einstein-recs').html(html);
                initSlider();
            },
            error: function () {
                $parentElement.spinner().stop();
            }
        });
    }
}

 /**
 * Processes a recommendation tile, with an already initialized category specific anchors array
 * @param {jQuery} $parentElement parent element where recommendations will show.
 * @param {Object} einsteinUtils cquotient object
 * @param {Array} anchorsArray array of objects representing anchors
 */
function processRecommendationsTile($parentElement, einsteinUtils, anchorsArray) {
    var recommender = $parentElement.data('recommender');

    var params = {
        userId: einsteinUtils.getCQUserId(),
        cookieId: einsteinUtils.getCQCookieId(),
        ccver: '1.01'
    };

    if (anchorsArray) {
        params.anchors = anchorsArray;
    }

    /**
    * Processes a recommendation responses
    * @param {Object} einsteinResponse cquotient object
    */
    function recommendationsReceived(einsteinResponse) {
        fillDomElement(einsteinResponse, $parentElement);
        $parentElement.spinner().stop();
    }

    if (einsteinUtils.getRecs) {
        einsteinUtils.getRecs(einsteinUtils.clientId, recommender, params, recommendationsReceived);
    } else {
        einsteinUtils.widgets = einsteinUtils.widgets || []; // eslint-disable-line no-param-reassign
        einsteinUtils.widgets.push({
            recommenderName: recommender,
            parameters: params,
            callback: recommendationsReceived
        });
    }
}

/**
 * Processes a recommendation tile, with an already initialized product specific anchors array
 * @param {jQuery} $parentElement parent element where recommendations will show.
 * @returns {Array} - containing an anchor object
 */
function createProductAnchor($parentElement) {
    return [{
        id: $parentElement.data('primaryProductId'),
        sku: $parentElement.data('secondaryProductId'),
        type: $parentElement.data('alternativeGroupType'),
        alt_id: $parentElement.data('alternativeGroupId')
    }];
}

/**
 * Rerieves data attributes from parent element and converts to gretel compatible recommenders array
 * @param {jQuery} $parentElement parent element where recommendations will show.
 * @returns {Array} - containing an anchor object
 */
function createCategoryAnchor($parentElement) {
    return [{ id: $parentElement.data('categoryId') }];
}

/**
 * Gets all placeholder elements, which hold einstein recommendations queries the details from the
 * einstein engine and feeds them back to the dom element
 */
function loadRecommendations() {
    var einsteinUtils = getEinsteinUtils();

    if (einsteinUtils) {
        var $recommendationTiles = $('.einstein-carousel');
        $recommendationTiles.each(function () {
            var $parentElement = $(this);

            $parentElement.spinner().start();
            if ($(this).closest('.experience-einstein-einsteinCarouselProduct').length) {
                return processRecommendationsTile($parentElement, einsteinUtils, createProductAnchor($parentElement));
            } else if ($(this).closest('.experience-einstein-einsteinCarouselCategory').length) {
                return processRecommendationsTile($parentElement, einsteinUtils, createCategoryAnchor($parentElement));
            }
            return processRecommendationsTile($parentElement, einsteinUtils);
        });
    }
}

/**
 * Initializes the slider with Einstein data in place
 * @param {jQuery} $parentElement parent element where recommendations will show.
 */
function initSlider() {
    $('.experience-einstein-carousel').each(function () {
        let $carousel = this.querySelector('.pd-carousel'); // vanilla js 'this'

        if ($carousel) {
            const $carouselSettings = $(this).find('.carousel-settings');
            const componentId = $carouselSettings.data('componentId');
            const smSlidesToShow = $carouselSettings.data('smSlides');
            const mdSlidesToShow = $carouselSettings.data('mdSlides');
            const lgSlidesToShow = $carouselSettings.data('lgSlides');
            const smCarouselDots = $carouselSettings.data('smDots');
            const mdCarouselDots = $carouselSettings.data('mdDots');
            const lgCarouselDots = $carouselSettings.data('lgDots');
            const smCarouselArrows = $carouselSettings.data('smArrows');
            const mdCarouselArrows = $carouselSettings.data('mdArrows');
            const lgCarouselArrows = $carouselSettings.data('lgArrows');
            const smCarouselScroll = $carouselSettings.data('smScroll');
            const mdCarouselScroll = $carouselSettings.data('mdScroll');
            const lgCarouselScroll = $carouselSettings.data('lgScroll');
            const spaceBtwnMobile = 16;
            const spaceBtwnTablet = 24;
            const spaceBtwnDesktop = 48;
            $($carousel).css({
                '--spaceBtwnMobile': `${spaceBtwnMobile}px`,
                '--spaceBtwnTablet': `${spaceBtwnTablet}px`,
                '--spaceBtwnDesktop': `${spaceBtwnDesktop}px`
            });

            let smloopCarousel = true;
            let mdloopCarousel = true;
            let lgloopCarousel = true;

            // scrollbar is enabled then carousel loop needs to be disabled.
            if (smCarouselScroll) {
                smloopCarousel = false;
            }

            if (mdCarouselScroll) {
                mdloopCarousel = false;
            }

            if (lgCarouselScroll) {
                lgloopCarousel = false;
            }

            /* eslint no-unused-vars: ["error", { "varsIgnorePattern": "pdCarousel" }]*/
            const pdCarousel = new Swiper($carousel, {
                direction: 'horizontal',
                loop: smloopCarousel,
                slidesPerView: smSlidesToShow,
                spaceBetween: spaceBtwnMobile,
                centerInsufficientSlides: true,
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                    enabled: smCarouselDots,
                    clickable: true
                },
                navigation: {
                    nextEl: `.swiper-nav-next-${componentId}`,
                    prevEl: `.swiper-nav-prev-${componentId}`,
                    enabled: smCarouselArrows
                },
                scrollbar: {
                    el: '.swiper-scrollbar',
                    enabled: smCarouselScroll,
                    draggable: true,
                    hide: false
                },
                breakpoints: {
                    544: {
                        spaceBetween: spaceBtwnTablet
                    },
                    769: {
                        loop: mdloopCarousel,
                        slidesPerView: mdSlidesToShow,
                        pagination: {
                            enabled: mdCarouselDots
                        },
                        navigation: {
                            enabled: mdCarouselArrows
                        },
                        scrollbar: {
                            enabled: mdCarouselScroll
                        }
                    },
                    1200: {
                        loop: lgloopCarousel,
                        slidesPerView: lgSlidesToShow,
                        spaceBetween: spaceBtwnDesktop,
                        pagination: {
                            enabled: lgCarouselDots
                        },
                        navigation: {
                            enabled: lgCarouselArrows
                        },
                        scrollbar: {
                            enabled: lgCarouselScroll
                        }
                    }
                }
            });
        }
    });
}

$(document).ready(function () {
    loadRecommendations();
});

$(window).smartresize(function () {
    initSlider();
});
